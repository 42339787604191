<template>
	<v-container>
		<v-container>
			<v-row>
				<v-col>
					<div class="headline mb-1 text-center">
						Report Form
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col
					cols="12" lg="6"
				>
					<v-card 
						class="rounded-lg" 
						style="background: #384154;"
						:loading="model_loading_"
					>
						<v-card-title class="white--text">Let us know what went wrong</v-card-title>

						<v-card-text>
							<v-row dense>
								<v-col>
									<v-text-field
										v-model="resourceData_.name"
										label="Name"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.name"
									>
										<template #label>
											<span class="red--text"><strong>* </strong></span>Name
										</template>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-text-field
										v-model="resourceData_.contact_number"
										label="Contact Number"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.contact_number"
									>
										<template #label>
											<span class="red--text"><strong>* </strong></span>Contact Number
										</template>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-text-field
										v-model="resourceData_.email"
										label="Email"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.email"
									>
										<template #label>
											<span class="red--text"><strong>* </strong></span>Email
										</template>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-text-field
										v-model="resourceData_.app_version"
										label="Application Version (Mobile App only)"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.app_version"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-text-field
										v-model="resourceData_.phone_model"
										label="Phone Model/Browser Type"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.phone_model"
									>
										<template #label>
											<span class="red--text"><strong>* </strong></span>Phone Model/Browser Type
										</template>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-textarea
										v-model="resourceData_.message"
										label="Description"
										solo
										dense
										class="rounded-lg"
										:error-messages="errors.message"
									>
										<template #label>
											<span class="red--text"><strong>* </strong></span>Description
										</template>
									</v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-file-input
										v-model="resourceData_.attachments"
										accept="image/*,.pdf"
										solo
										dense
										class="rounded-lg"
										:rules="rules"
										small-chips 
										show-size 
										multiple 
										clearable
										label="Attachments"
										:error-messages="errors.attachments"
									></v-file-input>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-btn
										block
										depressed
										color="primary"
										class="text-capitalize secondary--text radius-10 mb-3"
										@click="submitMessage()"
									>
										Send Message
									</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<!-- <v-col
					class="ml-15"
					cols="4"
				>
					<v-row>
						<v-col>
							<h3>Regional Office</h3>
						</v-col>
					</v-row>
					<v-row 
						v-for="(office) in resourceDataSet_" 
						:key="office.id"
					>
						<v-col>
							<h2>{{office.location}}</h2>
							<h3>{{office.company}}</h3>
							<p style="white-space: pre;">{{office.address | replace('{/n}','\n')}}</p>
							<p> <v-icon>mdi-phone</v-icon> {{office.contact_no}}</p>
						</v-col>
					</v-row>
				</v-col> -->
			</v-row>
		</v-container>
		<v-overlay
			:value="model_loading_"
			opacity= 0.7
			style="z-index: 21;"
		>
			<v-progress-circular
				:rotate="-90"
				:size="96"
				:width="15"
				:value="totalUploadPercent"
				color="primary"
			>
				{{ totalUploadPercent + '%'}}
			</v-progress-circular>
		</v-overlay>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;

export default {
	components:{
	},
	filters: {
		replace: function (st, rep, repWith) {
			const result = st.split(rep).join(repWith)
			return result;
		}
	},
	mixins: [
		resourceMixin,
		errorHandlerMixin,
	],
	data(){
		return {
			carouselNum: 0,
			errors: {},
			rules: [
				files => !files || !(files.reduce(function (acc, obj) { return acc + obj.size; }, 0) > 1.5e+7) || 'Combined attachment size should be less than 15 MB!'
			],
			totalUploadPercent: 0,
		}
	},
	computed: {
	},
	created(){
		//this.initialize_()
	},
	methods:{
		// getResourceData_(page = 1){
		// 	let payload = {
		// 		itemsPerPage: 99,
		// 		is_archived: 0,
		// 	}
		// 	this.model_loading_ = true
		// 	this.$api.getRegionalOfficeList(payload).then((res)=>{
		// 		let { data, } = res.data.result
		// 		this.resourceDataSet_ = [ ...this.resourceDataSet_, ...data ]
		// 	}).catch((err)=>{
		// 		this.errorHandler_(err)
		// 	}).finally(()=>{
		// 		this.model_loading_ = false
		// 	})
		// },
		submitMessage()
		{
			window.addEventListener('beforeunload', this.beforeLeaving)
			this.totalUploadPercent = 0
			this.errors = {}

			let formData = new FormData();
			formData.set('name', this.resourceData_.name);
			formData.set('contact_number', this.resourceData_.contact_number); 
			formData.set('email', this.resourceData_.email); 
			formData.set('app_version', this.resourceData_.app_version ?? 'desktop'); 
			formData.set('phone_model', this.resourceData_.phone_model); 
			formData.set('message', this.resourceData_.message); 

			for (var i = 0; i < this.resourceData_.attachments.length; i++) {
				formData.append('attachments[]',this.resourceData_.attachments[i]);
			}
			
			this.model_loading_ = true
			this.$api.submitReport(formData, this.onProgress).then((res)=>{
				this.$toast.success(res.data.message)
			}).catch((err)=>{
				this.errors = this.errorHandler_(err, ['name', 'contact_number', 'email', 'app_version', 'message', 'attachments'])
			}).finally(()=>{
				window.removeEventListener('beforeunload', this.beforeLeaving)
				this.model_loading_ = false
			})
		},
		onProgress(percent)
		{
			this.totalUploadPercent = percent
		},
		beforeLeaving(event)
		{
			event.preventDefault()
			event.returnValue = ""
		},
	},
}
</script>
<style>
.rounded-card{
    border-radius:50px;
}
</style>
